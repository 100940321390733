import React from "react";
import { LIST_TYPE, TEXT_FORMAT } from "../../data/content";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { openLinkOnSamePage } from "../../lib/appFunctions";
import ImageComponent from "../imageComponent";
import { doesStringContainBoldText, getBoldTextWithinString, renderBoldText } from "../../lib/stringFunctions";

export default function ContentTypeList({ body }) {
  const navigate = useNavigate();
  const items = body?.items;
  if (!items) {
    return null;
  }
  return (
    <div className="default-body-section-margin-top">
      {items.map((item, index) => {
        const listType = item?.listType;
        const text = item?.text ?? "";
        const { isSubheader, format } = item;
        if (text) {
          const link = item?.link;
          return renderListType({
            listType,
            text,
            index,
            link,
            navigate,
            isSubheader,
            format,
          });
        }
        const imageSrc = item?.imageSrc;
        if (imageSrc) {
          return (
            <ImageComponent src={imageSrc} alt={""} />
          );
        }
        return null;
      })}
    </div>
  );
}

function renderListType({ listType, text, index, link, isSubheader, format }) {
  switch (listType) {
    case LIST_TYPE.BULLET_NON_INDENTED:
      const parts = getBoldTextWithinString(text);
      return (
        <div
          key={`${index}_${text}`}
          className={classNames(
            "flex items-start",
            isSubheader ? "subheader-text" : ""
          )}
        >
          <div className={"non-indented-bullet mt-2.5"}></div>
          <div
            className={classNames(
              "indented-bullet-text",
              link ? "cursor-pointer underline content-type-link" : "",
              format === TEXT_FORMAT.BOLD ? "font-weight-500-important" : ""
            )}
            onClick={() => {
              if (link) {
                openLinkOnSamePage(link);
              }
            }}
          >
            {doesStringContainBoldText(text) ? renderBoldText(parts) : text}
          </div>
        </div>
      );
    case LIST_TYPE.BULLET_INDENTED:
      return (
        <div
          key={`${index}_${text}`}
          className={classNames(
            "ml-2",
            "flex items-start",
            isSubheader ? "subheader-text" : ""
          )}
        >
          <div className={"indented-bullet mt-2"}></div>
          <div
            className={classNames(
              "indented-bullet-text",
              link ? "cursor-pointer underline content-type-link" : ""
            )}
            onClick={() => {
              if (link) {
                openLinkOnSamePage(link);
              }
            }}
          >
            {text}
          </div>
        </div>
      );
    default:
      return (
        <div
          className={classNames(
            "content-type-list",
            link ? "cursor-pointer underline content-type-link" : "",
            isSubheader ? "subheader-text" : ""
          )}
          key={`${index}_${text}`}
          onClick={() => {
            if (link) {
              openLinkOnSamePage(link);
            }
          }}
        >
          {doesStringContainBoldText(text) ? renderBoldText(getBoldTextWithinString(text)) : text}
        </div>
      );
  }
}
