import { isVimcalEAVersion } from "../lib/appFunctions";
import { isLocal } from "../lib/envFunctions";

// notion page with all the content:
// https://www.notion.so/Vimcal-Help-Center-9b4e05b3fc8d44338fc444fb1399d72d

// hierarchy is:
// - section
//   - subsection
//     - topic
//       - header
//       - body
export const CONTENT_TYPE = {
  TEXT: "text",
  VIDEO: "video",
  IMAGE: "image",
  LINK: "link",
  LIST: "list",
};

export const LIST_TYPE = {
  DEFAULT: "default",
  BULLET_NON_INDENTED: "bullet_non_indented",
  BULLET_INDENTED: "bullet_indented",
};

export const TEXT_FORMAT = {
  BOLD: "bold",
  ITALIC: "italic",
  UNDERLINE: "underline",
};

export const VIDEO_TYPE = {
  YOUTUBE: "youtube",
  VIMEO: "vimeo",
};

export const CONTENT_PATH = {
  ABOUT: "about",
  SLOTS_VIMCAL_EA: "slots-vimcal-ea", // TODO: make sure this works for /EA on click
  COLOR_CODING_AND_TAGGING_VIMCAL_EA: "color-coding-and-tagging-vimcal-ea", // TODO: make sure this works for /EA on click
  VIMCAL_BIG_THREE: "vimcal-big-three",
  TIME_TRAVEL: "time-travel",
  TEAMS: "teams",
  ACCOUNT_SETUP: "account-setup",
  REFER_A_FRIEND: "refer-a-friend",
  MOST_POPULAR: "most-popular",
  CONFERENCING: "conferencing",
  INTERNAL_SCHEDULING: "internal-scheduling",
  GROUP_VOTE: "group-vote",
  CONTACT_GROUPS: "contact-groups",
  EDIT_YOUR_PROFILE: "edit-your-profile",
  PLANS_AND_PRICING: "plans-and-pricing",
  TEMPLATES: "templates",
  SLOTS: "slots",
  FOCUS_MODE: "focus-mode",
  TAGS_AND_METRICS: "tags-and-metrics",
  PERSONAL_LINKS: "personal-links",
  COMMAND_CENTER: "command-center",
  NLP: "nlp",
  TIME_ZONES: "time-zones",
  EXTERNAL_SCHEDULING: "external-scheduling",
  KEYBOARD_SHORTCUTS: "keyboard-shortcuts",
  EVENTS: "events",
  FAQ: "faq",
  FREE_TIME_FINDER: "free-time-finder",
  COLOR_CODING_AND_TAGGING: "color-coding-and-tagging",
  WEEKLY_METRICS: "weekly-metrics",
  POSSIBLE_ISSUES_GOOGLE: "possible-issues-google",
  INSANT_OPEN: "instant-open",
  GROUP_VOTE_SPREADSHEET: "group-vote-spreadsheet",
};

function getCompleteURL(path) {
  // if (isLocal()) {
  //   return `http://localhost:3001/${path}`;
  // }
  return `https://docs.vimcal.com/${path}`;
}

const WHAT_IS_VIMCAL_SUBSECTION = {
  name: "What is Vimcal?",
  path: CONTENT_PATH.ABOUT,
  topics: [
    {
      header: "About",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "We designed Vimcal to be the world’s fastest calendar for busy people and their teams! Think of it as the calendar for people with too many meetings.",
        },
      ],
    },
    {
      header: "Product demo",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "get a quick overview of the calendar",
        },
        {
          type: CONTENT_TYPE.VIDEO,
          video_type: VIDEO_TYPE.YOUTUBE,
          src: "https://www.youtube.com/embed/NozZ57u0f38?si=5eX3nghm0oGu1wiC", // need to put the embed version of the URL here
        },
      ],
    },
    {
      header: "Getting Started",
      bodies: [
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.ACCOUNT_SETUP),
          label: "Account Setup",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.CONFERENCING),
          label: "Conferencing",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.MOST_POPULAR),
          label: "Most Popular Features",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.VIMCAL_BIG_THREE),
          label: "Vimcal Big 3",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.TEAMS),
          label: "Vimcal For Teams",
        },
      ],
    },
    {
      header: "Related Articles",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Account Setup",
              link: getCompleteURL(CONTENT_PATH.ACCOUNT_SETUP),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Refer a friend",
              link: getCompleteURL(CONTENT_PATH.REFER_A_FRIEND),
            },
          ],
        },
      ],
    },
  ],
};

const PERSONAL_LINKS_SUBSECTION = {
  name: "Personal Links",
  path: CONTENT_PATH.PERSONAL_LINKS,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Personal Links are reusable booking links where you can set times you'd like to offer every week, e.g. 30-minute sales calls or hour-long office hours.",
        },
      ],
    },
    {
      header: "Choosing times to offer",
      bodies: [
        {
          text: "On the left-hand calendar, drag the time windows you'd like to offer on a weekly basis.",
        },
        {
          text: "We'll show these as bookable times every week to anyone who has your Personal Link, minus any conflicting events on your calendar marked as 'busy'.",
        },
        {
          text: "To block an entire day from being booked, e.g. you're flying that day, create an all-day 'busy' event.",
        },
        {
          text: "'Free' events will not count as conflicts and recipients will be able to book over them.",
        },
      ],
    },
    {
      header: "Label",
      bodies: [
        {
          text: "--- HEADER 2",
        },
        {
          text: "The label section is the title that appears on the booking page.",
        },
        {
          text: "Please note, this differs from the title of the event that gets created (see below).",
        },
      ],
    },
    {
      header: "Nickname",
      bodies: [
        {
          text: "--- HEADER 2",
        },
        {
          text: "The nickname section allows you to add a title that only you can see.",
        },
        {
          text: "This is useful when differentiating between Personal Links that have similar purposes but with small differences that you don't want to communicate to the recipient.",
        },
        {
          text: "For example, you may want to distinguish between 'fundraise 30m', 'fundraise 45m', 'fundraise (EDT only)', and 'fundraise (high-priority)'.",
        },
      ],
    },
    {
      header: "URL",
      bodies: [
        {
          text: "Easily customize your Personal links URL to better fit the nature of the meeting or your brand.",
        },
      ],
    },
    {
      header: "Time zone",
      bodies: [
        {
          text: "Dictate which time zones your Personal Link will default to.",
        },
        {
          text: "If you travel frequently, you can create separate links for the same meeting type but in different time zones. Discern them with different nicknames (see 'Nickname' above).",
        },
      ],
    },
    {
      header: "Calendar Invite Settings",
      bodies: [
        {
          text: "Calendar invite relates to the event details when a recipient schedules through your Personal Link. ",
        },
        {
          text: "Customize the event's title, duration, attendees list, conferencing option/location, and more.",
        },
      ],
    },
    {
      header: "Custom Questions",
      bodies: [
        {
          text: "Easily add additional fields to your booking page. ",
        },
        {
          text: "Pro Tip: If you enable the 'Company name' Cusotm Question, you can use it as a variable when populating the title of the invite that gets created.",
        },
      ],
    },
    {
      header: "Scheduling Settings",
      bodies: [
        {
          text: "In the bottom section of a Personal Link, you can set parameters for the times which show up in the booking link for the recipients.",
        },
        {
          text: "For example, you can dictate buffers to keep before or after meetings, how far in the future people can book, reminder emails, and rules for conflicts.",
        },
      ],
    },
    {
      header: "Choosing calendars to protect from conflicts",
      bodies: [
        {
          text: "The 'Check for conflicts' feature lets you select which calendars you'd like to prevent double booking on.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "Booking links you send out will hide any conflicting events you have on those calendars.",
        },
      ],
    },
    {
      header: "Ignoring conflicts",
      bodies: [
        {
          text: "On the other hand, there are times you'll want to double book over existing meetings on your calendar, like when you need to schedule a high-priority meeting or a job interview.",
        },
        {
          text: "You get two override options in Vimcal:",
        },
        {
          text: "Ignore all conflicts",
          isSubheader: true
        },
        {
          text: "Toggle this on if you want the booking link to disregard all conflicts on your calendar and always show all the selected Slots to the recipient.",
        },
        {
          text: "Pro Tip: Reserve this for the highest priority meetings to prevent accidental double bookings.",
        },
        {
          text: "Ignore my internal conflicts",
          isSubheader: true
        },
        {
          text: "Toggle this on to allow recipients to book over meetings you define as 'interal'.",
        },
        {
          text: "Pro Tip: Set what company domains or individual email addresses count as an internal meeting in your General Settings.",
        },
        {
          text: "--- ADD LINK TO SECTION OF DOCS ARTICLE FOR INTERNAL DOMAINS. NAME THIS LINK 'View Internal Meeting Settings'",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Sharing your Personal Links",
      bodies: [
        {
          text: "There are two ways to share your booking link with someone else.",
        },
        {
          text: "First, you can copy just the URL. Copy a given link's URL from anywhere within Vimcal by simply using the global hotkeys 'YY1', 'YY2', etc.",
        },
        {
          text: "Hover over the 'Copy Link' button to see that link's specific shortcut.",
        },
        {
          text: "--- IMAGE OF SHORTCUT WHEN HOVERING OVER COPY LINK BUTTON",
        },
        {
          text: "Secondly, you can copy a text block with a few handpicked time. We check your link that these are still available.",
        },
        {
          text: "This will resemble the text you copy from our Slots feature and allow you convenience without losing the human.",
        },
        {
          text: "--- IMAGE OF SLOTS WHEN HOVERING OVER COPY SHARE -> COPY SLOTS",
        },
      ],
    },
    {
      header: "Embedding links on your website",
      bodies: [
        {
          text: "You can embed Personal Links on your company's website to help drive conversion.",
        },
        {
          text: "Click 'Share' and the 'Embed on website' option, then simply copy and paste the HTML into your website's code.",
        },
      ],
    },
  ],
};

const INSTANT_OPEN_SUBSECTION = {
  name: "Instant Open",
  path: CONTENT_PATH.INSANT_OPEN,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Quickly open any invite or URL in Vimcal even faster with Instant Open!",
        },
        {
          text: `Simply type "Cmd + O" and the title of an event you see on your calendar.`,
        },
        {
          text: "If an event is already selected, Instant Open will also let you open any URLs in that event's description.",
        },
      ],
    },
  ],
};

const NLP_SUBSECTION = {
  name: "Creating events with NLP",
  path: CONTENT_PATH.NLP,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "One of the most used features in Vimcal is creating meetings with Natural Language (NLP).",
        },
        {
          text: "In the purple text input at the top of the event form, you can type things like 'Coffee tomorrow at 3pm with John' and Vimcal will fill in all the details for you!",
        },
        {
          text: "Once you get a taste, you'll never want to go back to manually filling out each field.",
        },
        {
          text: "--- GIF OF NLP",
        },
      ]
    },
    {
      header: "The Golden Rule: Title First",
      bodies: [
        {
          text: "There's only one rule to follow when using the NLP feature — always start with the title of the event.",
        },
        {
          text: "After that, you can type out the remaining details in any order.",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `Here are 2 examples that have the same information, but are written in different orders.`,
            },
            {
              text: `"Coffee tomorrow at 5pm CST with michael@dundermifflin.com at Central Perk for 45 minutes"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: '"Coffee at Central Perk with michael@dundermifflin.com for 45 minutes at 5pm CST tomorrow"',
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
      ],
    },
    {
      header: "Keywords",
      bodies: [
        {
          text: "We detect the field you want to update via intuitive keywords:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"For" → duration`,
            },
            {
              text: `"Coffee for 45 minutes"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: `"Design sync for 1 hr 30 min"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"With" → attendees`,
            },
            {
              text: `"Coffee with michael@dundermifflin.com and dwight@dundermifflin.com"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"At" → location`,
            },
            {
              text: `"Coffee at Central Perk"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"On" or "via" → Conferencing solution`,
            },
            {
              text: `"Product Review on Zoom"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: `"Catch up via phone"`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
      ],
    },
    {
      header: "Date and Time",
      bodies: [
        {
          text: "You can type the time and date in any order you'd like.",
        },
        {
          text: "For example, 'Tuesday at 3pm', '3pm Tuesday', or '3pm with John on 6/4' will all give you the correct date and time.",
        },
      ],
    },
    {
      header: "Specifying time zone",
      bodies: [
        {
          text: "You can add a time zone immediately by typing the abbreviation immediately after the start time of the meeting.",
        },
        {
          text: "For example: 'Sprint plan at 5pm CST'.",
        },
        {
          text: "We'll even activate Time Travel on your behalf so you can double check that the time works for your time zone!",
        },
      ],
    },
    {
      header: "Switching to a different calendar",
      bodies: [
        {
          text: "If you want to save the event on a calendar different than the current default, simply type '/' and you'll see a dropdown of other calendars you can write the event to.",
        },
        {
          text: "--- IMAGE OF DROPDOWN AFTER PRESSING /",
        },
      ],
    },
    {
      header: "Applying event templates",
      bodies: [
        {
          text: "To move even quicker, you can type the name of an event template directly in the NLP input to quickly pre-fill the details of the meeting.",
        },
        {
          text: "--- IMAGE OF TEMPLATE DROP DOWN",
        },
        {
          text: "If you haven't fully explored our templates feature, click on the link below to learn more: ",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.TEMPLATES),
          label: "Templates & Sticky Notes",
        },
      ],
    },
  ],
};

const COMMAND_CENTER_SUBSECTION = {
  name: "Command Center",
  path: CONTENT_PATH.COMMAND_CENTER,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "The Command Center is Vimcal's central hub.",
        },
        {
          text: "It allows you to access every feature and setting within Vimcal without ever leaving your keyboard.",
        },
        {
          text: "Hit Cmd + K (Mac) or Ctrl + K (PC) and start typing out a command.",
        },
      ],
    },
    {
      header: "Contextual menu",
      bodies: [
        {
          text: "The suggested options in the Command Center will change depending on what you're doing in the app.",
        },
        {
          text: "For example, you'll see a different set of options when you open it while looking at your calendar vs. when you're creating an invite.",
        },
        {
          text: "Any option that also has a keyboard shortcut will be displayed so you can learn it and eventually skip the speedy Command Center in the future.",
        },
        {
          text: "--- IMAGE OF COMMAND CENTER AND CONTEXTUAL MENU TO ACCEPT/DECLINE INVITE"
        }
      ],
    },
    {
      header: "Jumping to specific dates ",
      bodies: [
        {
          text: "With the Command Center, it's never been easier to jump to a specific date on your calendar.",
        },
        {
          text: "The best part is that it can accept Natural Language to navigate on your behalf. Try typing 'July 24, 2025' or 'Last Friday'.",
        },
      ],
    },
    {
      header: "Changing settings",
      bodies: [
        {
          text: "You can set most of your settings directly in the Command Center! Just press Cmd + K (Mac) or Ctrl + K (PC) and type in the setting.",
        },
        {
          text: "Try 'Dark Mode' to toggle the theme in Vimcal.",
        },
        {
          text: "Optionally, you can type “Settings” to view all your settings within Vimcal.",
        },
      ],
    },
    {
      header: "Shortcuts ",
      bodies: [
        {
          text: "If you ever forget a shortcut or where to find something, you can always default back to the Command Center.",
        },
      ],
    },
  ],
};

const POSSIBLE_ISSUES_GOOGLE_SUBSECTION = {
  name: "Possible blockers you may encounter (Google)",
  path: CONTENT_PATH.POSSIBLE_ISSUES_GOOGLE,
  topics: [
    {
      header: "You're stuck on the sign-in screen",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "This could be for a multitude of reasons but the two most common reasons are:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "1. You haven’t checked off all permissions",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "To resolve, select ‘Sign in’ one more time and check off any permissions you may have missed.",
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "2. Browser is blocking cookies",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "If using Chrome, go to the address bar and look all the way to the right. You may find an eyeball icon with a slash through it.",
            },
          ],
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.amazonaws.com/cookies.png",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Select “Site not working?” → “Allow cookies”",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Retry sign-in",
            },
          ],
        },
      ],
    },
    {
      header: "400 Error",
      bodies: [
        {
          label:
            "Linked here is a one-minute Loom showing how to lift 400 errors.",
          src: "https://www.loom.com/share/28980f30684b487694d6c1fa65370c07",
          type: CONTENT_TYPE.LINK,
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Someone with GSuite Admin Access goes to the Admin Console",
            },
            {
              text: "Step 2: On the left, select ‘Security’ → ‘Access and data control’ → ‘API controls’",
            },
            {
              text: "Step 3: Select MANAGE THIRD-PARTY APP ACCESS on the right",
            },
            {
              text: "Step 4: Near the middle-left of your screen, select the ‘Add app’ dropdown → ‘Auth App Name Or Client ID’",
            },
            {
              text: "Step 5: In the Search bar, type ‘Vimcal’ and select the blue Search button",
            },
            {
              text: "Step 6: ‘Select’ Vimcal on the right and check all configuration boxes",
            },
            {
              text: "Step 8: Choose “Trusted: Can access all Google services”",
            },
            {
              text: "Step 9: Choose “Trusted: Can access all Google services” and select CONFIGURE",
            },
          ],
        },
        {
          label: "Step 10: Sign into Vimcal",
          src: "https://calendar.vimcal.com/login",
          type: CONTENT_TYPE.LINK,
        },
      ],
    },
  ],
};

const REFER_A_FRIEND_SUBSECTION = {
  name: "Refer a friend",
  path: CONTENT_PATH.REFER_A_FRIEND,
  topics: [
    {
      bodies: [
        {
          text: "Enjoy using Vimcal? Invite your co-workers and friends to give them access too! ",
        },
        {
          text: "Cmd + K (Mac) or Ctrl + K (PC) and typing refer should get you there! ",
        },
        {
          text: "--- IMAGE OF REFERRAL PORTAL",
        },
        {
          text: "You can share your referral URL (recommended) or send them an invite email through Vimcal.",
        },
        {
          text: "If the person you referred ends up joining, you'll both receive a free month! ",
        },
      ],
    },
  ],
};

const ACCOUNT_SETUP_SUBSECTION = {
  name: "Account Setup",
  path: CONTENT_PATH.ACCOUNT_SETUP,
  topics: [
    {
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Welcome to Vimcal! Let’s get you set up.",
            }
          ]
        }
      ]
    },
    {
      header: "Using the Vimcal Desktop App",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Download the Vimcal Desktop app for your device:",
            },
            {
              text: "Mac with Apple Silicon (M1,M2,M3,M4)",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              link: "https://vimcal-m1.s3.us-west-1.amazonaws.com/Vimcal-1.0.30-arm64.dmg",
            },
            {
              text: "Mac with Intel",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              link: "https://vimcal-production.s3.us-west-1.amazonaws.com/Vimcal-1.0.30.dmg",
            },
            {
              text: "Windows",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              link: "https://vimcal-pc.s3.amazonaws.com/Vimcal-1.0.30.exe",
            },
          ],
        },
      ],
    },
    {
      header: "Using Vimcal in the browser",
      bodies: [
        {
          text: "You may use Vimcal in your browser of choice. The experience will be mostly the same as that of the Vimcal Desktop app, with the exception of the Menu Bar App for Mac users.",
        },
        {
          label: "Start using Vimcal in the Browser.",
          type: CONTENT_TYPE.LINK,
          src: "https://calendar.vimcal.com"
        },
      ],
    },
    {
      header: "Authenticating with Google",
      bodies: [
        {
          text: "Please make sure to check all of the permissions in the Google login flow in order to proceed.",
        },
        {
          label: "Possible issues you may encounter.",
          src: getCompleteURL(CONTENT_PATH.POSSIBLE_ISSUES_GOOGLE),
          type: CONTENT_TYPE.LINK,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.amazonaws.com/permissions-screen.png",
        },
      ],
    },
    {
      header: "Permissions",
      bodies: [
        {
          text: "Below are the permissions we request and how we use them:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "View calendar resources on your domain.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "This allows you to book conference rooms for your meetings.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "See info about users on your domain.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "This allows Vimcal to auto-complete email addresses when inviting guests to a meeting.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "See and download contact info automatically saved in your “Other contacts”.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "This allows Vimcal to auto-complete email addresses when inviting guests to a meeting.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "We will never contact, sell, or share your contacts.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "See and download your contacts.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "This allows Vimcal to auto-complete email addresses when inviting guests to a meeting.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "We will never contact, sell, or share your contacts.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "See, edit, share, and permanently delete all the calendars you can access using Google Calendar.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "This is simply 'read & write access' to your calendar, which is a necessity for basic functionality to work in a calendar app.",
            },
          ],
        }
      ],
    },
    {
      header: "Microsoft Sign in flow",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Confirm you’re using either Chrome or Brave as your browser",
            },
            {
              text: "Step 2: Go to https://calendar.vimcal.com/home",
            },
            {
              text: "Step 3: Select ‘Sign in with Microsoft’, using your work email",
            },
            {
              text: "Step 4: Accept all permissions",
            },
          ],
        },
      ],
    },
    {
      header: "Related articles",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Most popular",
              link: getCompleteURL(CONTENT_PATH.MOST_POPULAR),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Refer a friend",
              link: getCompleteURL(CONTENT_PATH.REFER_A_FRIEND),
            },
          ],
        },
      ],
    },
  ],
};

const CONFERENCING_SUBSECTION = {
  name: "Conferencing",
  path: CONTENT_PATH.CONFERENCING,
  topics: [
    {
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "Vimcal supports direct integrations with Zoom, Google Meet, and Microsoft Teams, as well as custom conferencing options.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "To access your conferencing settings, go to the “Conferencing” section of your account settings.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Alternatively, you can access it via the Command Center by pressing Cmd + K (Mac) or Ctrl + K (PC) and typing “Conferencing Settings”.",
        },
      ]
    },
    {
      header:
        "Zoom",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "Step 1: Click the blue 'Login' button to authenticate your Zoom account.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "_____INSERT SCREENSHOT OF ZOOM LOGIN BUTTON_____",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Step 2: In the Zoom pop-up module, sign in with your corresponding email account. You may need to scroll down to view sign-in options.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Step 3: Check the box “Allow this app to use my shares access permissions”. (Note: this is crucial for the integration to work correctly so please don’t skip this step!)",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "_____INSERT SCREENSHOT OF ZOOM PERMISSIONS CHECKBOX____",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Step 4: Click 'Allow'. When prompted to return back to Vimcal, select 'Open Vimcal'.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Once complete, you should see the word 'Authenticated' where the blue Login button used to be.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Bonus: If you'd like to set Zoom as your default conferencing option, click on 'Make default' next to the Zoom header.",
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "_____INSERT SCREENSHOT OF DEFAULT CONF SCREENSHOT_____",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Google Meet",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "When signing into Vimcal with a Google account, we carry over the option to use Google Meet for all meetings. No setup is required.",
        },
      ],
    },
    {
      header: "Microsoft Teams",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "When signing in with a Microsoft or Outlook account, we sync with your Microsoft Teams account for all meetings. No setup is required.",
        },
      ],
    },
    {
      header: "Custom Conferencing",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "If you'd like to use any other third-party conferencing solution, you can easily add the personal meeting link name and URL for that platform here.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "_____INSERT SCREENSHOT OF CUSTOM CONF SCREENSHOT_____",
        },
      ],
    },
  ],
};

const TEAMS_SUBSECTION = {
  name: "Vimcal for Teams",
  path: CONTENT_PATH.TEAMS,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Vimcal is designed specifically for busy teams.",
        },
        {
          text: "After using Vimcal, your team will move faster through the calendar and reduce time spent scheduling both internal and external meetings.",
        },
      ],
    },
    {
      header: "Creating a Team",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `1. Access the Command Center via Cmd + K (Mac) or Ctrl + K (PC) → 'Team plan' or go to the Team section of your Settings.`,
            },
            {
              imageSrc: "", // TODO: add
            },
            {
              text: "2. Once there, you may either 'Create team' or 'add teammates'",
            },
            {
              imageSrc: "", // TODO: add
            },
            {
              text: "3. You're done!",
            },
          ],
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: `If you'd like to remove any team members, it can be done in the same 'Team' area by the admin of the Team Plan.`,
        },
      ],
    },
    {
      header: "Team Features",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: `Once you've onboarded you're team onto Vimcal, you'll want to take advantage of our team features:`,
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Internal Scheduling",
              link: getCompleteURL(CONTENT_PATH.INTERNAL_SCHEDULING),
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Group Vote",
              link: getCompleteURL(CONTENT_PATH.GROUP_VOTE),
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Contact Groups",
              link: getCompleteURL(CONTENT_PATH.CONTACT_GROUPS),
            },
          ],
        },
      ],
    },
  ],
};

const EDIT_YOUR_PROFILE_SUBSECTION = {
  name: "Profile and Social Links",
  path: CONTENT_PATH.EDIT_YOUR_PROFILE,
  topics: [
    {
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "This section will cover how to customize your externally-facing profile when people schedule with you.",
        },
      ],
    },
    {
      header: "Profile Photo & Social Links",
      bodies: [
        {
          text: "Your photo and social links will appear in two places.",
        },
        {
          text: "First is your booking links. This adds a human touch when other people book with you, and also allows them to connect with you prior to the meeting.",
        },
        {
          text: "--- IMAGE HERE OF BOOKING LINK",
        },
        {
          text: "Secondly, these will both show in the attendees section of an event you're invited to, visible only to other Vimcal users.",
        },
        {
          text: "--- IMAGE HERE OF ATTENDEE SECTION",
        },
      ],
    },
    {
      header: "Username",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "Your username is your unique identifier that's used in all your booking links. For example, if you set your username to 'johnsmith', your Personal Link for a 30 minute meeting will be:",
        },
        {
          text: "'https://book.vimcal.com/p/johnsmith/30min'",
        },
        {
          text: "Please note that changing your username will break all existing links you've shared publicly, so please make sure to update them and change this setting sparingly.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    }
  ],
};

const PLANS_AND_PRICING_SUBSECTION = {
  name: "Plans and Pricing",
  path: CONTENT_PATH.PLANS_AND_PRICING,
  topics: [
    {
      header: "Plans",
      bodies: [
        {
          type: CONTENT_TYPE.LINK,
          src: "https://www.vimcal.com/pricing",
          label: "View all plans and pricing",
        },
      ],
    },
  ],
};

const WHERE_TO_FIND_CONTENT = {
  type: CONTENT_TYPE.TEXT,
  text: "Where to find",
  isSubheader: true,
};

const HOW_IT_WORKS = {
  type: CONTENT_TYPE.TEXT,
  text: "How it works",
  isSubheader: true,
}

const HOW_TO_CREATE = {
  type: CONTENT_TYPE.TEXT,
  text: "How to create",
  isSubheader: true,
};

const getSubtitleBody = (subtitle) => {
  return {
    type: CONTENT_TYPE.TEXT,
    text: subtitle,
    isSubheader: true,
  };
}

const HOW_TO_USE = {
  type: CONTENT_TYPE.TEXT,
  text: "How to use",
  isSubheader: true,
};

const SEE_IN_ACTION = {
  type: CONTENT_TYPE.TEXT,
  text: "See in action",
  isSubheader: true,
}

const getSlotsContentURL = () => {
  return isVimcalEAVersion() ? getCompleteURL(CONTENT_PATH.SLOTS_VIMCAL_EA) : getCompleteURL(CONTENT_PATH.SLOTS);
}

const getTaggingContentURL = () => {
  return isVimcalEAVersion() ? getCompleteURL(CONTENT_PATH.TAGGING_VIMCAL_EA) : getCompleteURL(CONTENT_PATH.COLOR_CODING_AND_TAGGING);
}

const MOST_POPULAR_SUBSECTION = {
  name: "Most popular features",
  path: CONTENT_PATH.MOST_POPULAR,
  topics: [
    {
      header: "Slots",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: `The fastest way to share your availability with others! Vimcal’s flagship feature is easy to use and designed to save you time.`,
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: `Simply press "A" and start dragging available times that you'd like to offer. These time slots will be formatted in real-time, ready for you to copy.`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getSlotsContentURL(),
          label: "Learn more about Slots",
        },
      ],
    },
    {
      header: "Time Travel",
      bodies: [
        {
          text: "Whether you work remotely or have a global clientele, coordinating across time zones is time consuming and error-prone.",
        },
        {
          text: "In Vimcal, you can instantly travel to anywhere in the world to coordinate as if you were there.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.TIME_TRAVEL),
          label: "Learn more about Time Travel",
        },
      ],
    },
    {
      header: "Command Center",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "Quickly search and navigate to different features and settings by pressing Cmd + K (Mac) or Ctrl + K (PC)!",
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.COMMAND_CENTER),
          label: "Learn more about Command Center",
        },
      ],
    },
    {
      header: "Personal Links",
      bodies: [
        {
          text: "Whether you’re looking to offer times for teammates to schedule office hours or creating a static booking link for interviews, Personal Links has you covered!",
        },
        {
          text: `Set your preferred availability per link and start getting calls scheduled.`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.PERSONAL_LINKS),
          label: "Learn more about Personal Links",
        },
      ],
    },
    {
      header: "Natural Language (NLP)",
      bodies: [
        {
          text: "In Vimcal, creating an event is as intuitive as describing it in a conversation.",
        },
        {
          text: `There's only one rule - always start with the title first. Everything else can come in any order afterwards.`,
        },
        {
          type: CONTENT_TYPE.LINK,
          src: getCompleteURL(CONTENT_PATH.NLP),
          label: "Learn more about NLP",
        },
      ],
    },
    {
      header: "Related Articles",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Free Time Finder",
              link: getCompleteURL(CONTENT_PATH.FREE_TIME_FINDER),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Group Vote",
              link: getCompleteURL(CONTENT_PATH.GROUP_VOTE),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Color coding and Tagging",
              link: getTaggingContentURL(),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Weekly Metrics",
              link: getCompleteURL(CONTENT_PATH.WEEKLY_METRICS),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Templates",
              link: getCompleteURL(CONTENT_PATH.TEMPLATES),
            },
          ],
        },
      ],
    },
  ],
};

const SLOTS_VIMCAL_EA_SUBSECTION = {
  name: "Slots",
  path: CONTENT_PATH.SLOTS_VIMCAL_EA,
  topics: [
    {
      bodies: [
        {
          text: "Slots is designed to help you easily share your executive’s availability with others in a personalized way. Instead of using a generic scheduling link, you can choose specific time slots and customize the booking experience for each person you share with.",
        },
      ]
    },
    {
      header: "How it works",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: `1. Select "Slots" from the Quick Actions menu in the side panel.`
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "2. Start dragging the available times you want to share. These time slots will be formatted in real-time, ready for you to copy and share."
            },
          ],
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Slots/Slots+walk+through.gif",
          type: CONTENT_TYPE.IMAGE,
        }   
      ]
    },
    {
      header: "Adding different time zones to Slots:",
      bodies: [
        {
          text: `If you need to offer time slots in multiple time zones, click the "Z" button or the "+" sign below the Slots copy.`
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Add+time+zone+.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "Toggling this ON will allow you to include time slots in the other time zones you've selected."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Toggle+time+zone.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "You can add up to 5 additional time zones."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Plaintext+%2B+URL.png",
          type: CONTENT_TYPE.IMAGE,
        }
      ]
    },
    {
      header: "Holds",
      bodies: [
        {
          text: "When you share a booking link with multiple time slots, you might want to temporarily reserve some of those times for your guest. The Holds feature lets you place temporary blocks on specific time slots while keeping them available in your booking link."
        },
        HOW_TO_CREATE,
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: `1. After selecting your availability, expand the “Holds” section.`
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "2. You can assign a title, and color for these blocks."
            },
          ],
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Create+Hold.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "How Hold Blocks Are Cleared",
          isSubheader: true,
        },
        {
          text: "One of the most convenient aspects of our Hold feature is its intelligent automatic cleanup. Hold blocks are designed to disappear seamlessly in two scenarios:"
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Automatic Removal After Booking",
          isSubheader: true,
        },
        {
          text: "When a guest schedules their meeting from your Slots booking link, any corresponding Hold blocks are automatically removed from the calendar. "
        },
        {
          text: "This eliminates the need for EAs to manually clean up these temporary placeholders, saving valuable time and reducing calendar clutter."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Select+time+.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Booking+page+confirmation.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "Before:",
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Holds+before.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "After:",
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Holds+After+being+converted.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "Manual Event Creation",
          isSubheader: true,
        },
        {
          text: "For EAs using the plain text version of Slots, creating events from Hold blocks is just as effortless:"
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Plaintext+Holds.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "1. After a guest has informed you of their preferred time, simply click on the desired Hold block."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Convert+plaintext+holds+step+one.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "2. Select the time to convert this hold into a meeting invite."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Convert+plaintext+holds+2.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Convert+plaintext+holds+3.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "3. All other related Hold blocks will be automatically cleared once the event is created."
        },
        {
          text: "This streamlined approach ensures that calendars remain clean and organized while minimizing the administrative overhead for Executive Assistants."
        }          
      ]
    },
    {
      header: "High-priority meetings",
      bodies: [
        {
          text: "Sometimes you may want to allow certain meetings to take priority over existing calendar events. We offer two options to help you manage these scheduling priorities:"
        },
        {
          text: "Ignore Conflicts",
          isSubheader: true,
        },
        {
          text: "This option allows your guests to book meetings even when you have existing events scheduled for those time slots. "
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "You're willing to reschedule any existing commitments for these meetings",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
            },
            {
              text: "You want to keep time slots available regardless of your current calendar status",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
            },
            {
              text: "You need maximum flexibility for high-priority bookings",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
            },
            
          ],
        },
        {
          text: "Ignore Internal Conflicts",
          isSubheader: true,
        },
        {
          text: "This option specifically lets guests book over calendar events with internal teammates while respecting external commitments. "
        },
        {
          text: "It’s perfect for when:" 
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "You want to prioritize external meetings over internal events",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
            },
            {
              text: "Your calendar contains internal blocks or tentative holds that can be moved",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
            },
          ],
        },
        {
          text: `*Note: You can define which email addresses and company domains count as an "internal" meeting by going to the Command Center (<strong>Cmd + K</strong> for Mac or <strong>Ctrl + K</strong> for PC) > <strong>Go to settings</strong> > <strong>General</strong> > <strong>Internal Domains and Emails</strong>.`
        },
        {
          text: "Both options help you maintain scheduling flexibility while ensuring high-priority meetings can be booked when needed."
        },
        {
          text: "You can find them under the “Meeting details” section."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/ignore+all+conflict.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "Previously sent",
          isSubheader: true,
        },
        {
          text: "There may be times when you want to look at the times that you’ve sent out or reuse time slots you've already offered. "
        }, 
        {
          text: `The "Previously Sent" feature allows you to do this quickly, without having to start from scratch.`
        },
        HOW_IT_WORKS,
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `1. Type "A" or select Slots under Quick Actions from the sidebar.`,
              listType: LIST_TYPE.DEFAULT,
            },
            {
              text: `2. Click "Previously sent" and it will pull up the time slots you offered.`,
              listType: LIST_TYPE.DEFAULT,
            },
          ],
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/previously+sent.png",
          type: CONTENT_TYPE.IMAGE,
        }, 
        {
          text: "You'll see three icons when viewing your previously sent Slots:"
        },
        {
          text: "1. Archive - Selecting this will archive the booking link."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Archive+slots.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "Reuse Slots - Use this option if you'd like to create a new booking link with the same times you’ve already offered."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Reuse+Holds.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "3. View - Toggling this ON will display the time slots on the calendar so you can plan around them."
        }, 
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Display+previously+sent+slots.png",
          type: CONTENT_TYPE.IMAGE,
        }
      ]
    }
  ]
}

const SLOTS_SUBSECTION = {
  name: "Slots",
  path: CONTENT_PATH.SLOTS,
  topics: [
    {
      bodies: [
        {
          text: "We designed availability sharing to always feel fast and personal with Slots — the most popular feature in Vimcal.",
        },
        {
          text: "Instead of tediously typing out each time window you'd like to offer someone, simply drag them on your calendar and we'll type it out for you to copy.",
        },
        {
          text: "Choosing specific times for specific people gives you much more control over your time than using a generic booking link.",
        },
        {
          text: "--- IMAGE OF SLOTS GIF",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement with Slots GIF
        },
        WHERE_TO_FIND_CONTENT,
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "The circular green button at the bottom right of your calendar",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "'A' on your keyboard",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Command Center → “Slots” ",
              isIndented: true,
            },
          ],
        },
      ],
    },
    {
      header: "Sending Slots in different time zones",
      bodies: [
        {
          text: "When you're scheduling with multiple people in different timezones, easily display all timezones in line with the “Show all time zones option”.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Slots + Time Travel",
      bodies: [
        {
          text: "--- HEADER 2",
        },
        {
          text: "Vimcal features are designed to work in perfect harmony with each other.",
        },
        {
          text: "Combining Slots and Time Travel not only allows you to pick times that work for both you and the person you're scheduling with, but also to send it in their time zone as a courteous personal touch.",
        },
        {
          text: "You can have up to 5 total time zones!",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Displaying all time zones",
      bodies: [
        {
          text: "--- HEADER 2",
        },
        {
          text: "When you're scheduling with people in multiple time zones, you also have the option to display the times for all time zones you selected when Time Travling.in line with the “Show all time zones option”.",
        },
        {
          text: "Simply check the 'Show all time zones' option.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Customizing the copied text",
      bodies: [
        {
          text: "Different situations might call for different writing styles for the times you want to offer. Personalize them to your liking with the options below.",
        },
      ],
    },
    {
      header: "The Four Formats",
      bodies: [
        {
          text: "--- HEADER 2",
        },
        {
          text: "Vimcal offers four formats, with and without booking links, that you can easily copy when sending out your availabilities.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "1) Hyperlinked Text",
          isSubheader: true
        },
        {
          text: "This option blends a booking link naturally in each Slot you offer.",
        },
        {
          text: "Any line the recipient clicks on will shortcut to that day's times in the booking link..",
        },
        {
          text: "You don't lose the personal touch while sending out a booking link since you still handpick individual times for the recipient.",
        },
        {
          text: "Pro Tip: This is the most used format in Vimcal. Since it contains HTML, it's best reserved for email or Slack.",
        },
        {
          text: "--- IMAGE OF HYPERLINKED SLOTS IN EMAIL",
        },
        {
          text: "2) Plaintext Only",
          isSubheader: true
        },
        {
          text: "Sometimes the social dynamic makes sending a booking seem out of place.",
        },
        {
          text: "In these cases, you can copy your availabilities as just text.",
        },
        {
          text: "Pro Tip: This is the most flexible option and can be pasted anywhere.",
        },
        {
          text: "3) Plaintext + URL",
          isSubheader: true
        },
        {
          text: "This option combines the best of both formats above, giving you the flexibility of 'Plaintext Only' with the efficiency of having a booking link.",
        },
        {
          text: "It's also the most 'human' way to fit a booking link into a scheduling conversation.",
        },
        {
          text: "Pro Tip: This is great for text messages or social media DMs if you want both the Slots spelled out and a booking link to be included as it does not contain HTML.",
        },
        {
          text: "4) URL only",
          isSubheader: true
        },
        {
          text: "For when you just need the URL.",
        },
        {
          text: "Pro Tip: Use the shortcut Command + Shift + C (Mac) or Ctrl + Shift + C (PC) to copy just the URL!",
        },
      ],
    },
    {
      header: "Default Style",
      bodies: [
        {
          text: "Once you’ve had a chance to look over each booking link style, you can now choose which style you'd like as the default!",
        },
        {
          text: "In the upper right hand corner, quickly access Slots settings by clicking the gear icon!",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "Under the Copyable Text header, choose your default style.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Popular Workflows",
      bodies: [
        {
          text: "This feature reduces the amount of clicks your recipient will need to book the meeting by pre-filling their info. Most importantly, it adds a warm message and thoughtful touch when they open the booking page.",
        },
        {
          text: "All you have to do is add the recipient's name and email beforehand.",
        },
      ],
    },
    {
      header: "Previously sent Slots",
      bodies: [
        {
          text: "Quickly re-surface previous Slots you've copied. This is a time saver when you want to send the same Slots out to multiple people.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "Tip: Press 'Enter' to quickly populate the last Slots you selected.",
        },
      ],
    },
    {
      header: "Personalizing the booking link",
      bodies: [
        {
          text: "This feature reduces the amount of clicks your recipient will need to book the meeting by pre-filling their info. Most importantly, it adds a warm message and thoughtful touch when they open the booking page.",
        },
        {
          text: "All you have to do is add the recipient's name and email beforehand.",
        },
      ],
    },
    {
      header: "Using Templates with Slots ",
      bodies: [
        {
          text: "One of our more popular workflows in Vimcal is to leverage an event template and apply it to the slots you’re going to offer. ",
        },
        {
          text: "An example could be scheduling individual onboarding calls for a group.",
        },
        {
          text: "Once you've selected the times you'd like to offer, simply press Command + Semicolon (Mac) or Ctrl + Semicolon (PC) and choose the template you’d like to apply. ",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "This will pre-fill all details like duration, title, attendees, etc. that you may have added to the template.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Slot Settings",
      bodies: [
        {
          text: "This settings module allows you to further customize and set defaults for future Slots!",
        },
      ],
    },
    {
      header: "Copyable Text",
      bodies: [
        {
          text: "Once you've set your default style, you can choose to edit the specific slots message copy for each booking link style. ",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Choosing calendars to protect from conflicts",
      bodies: [
        {
          text: "The 'Check for conflicts' feature lets you select which calendars you'd like to prevent double booking on.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
        {
          text: "Booking links you send out will hide any conflicting events you have on those calendars.",
        },
      ],
    },
    {
      header: "Ignoring conflicts",
      bodies: [
        {
          text: "On the other hand, there are times you'll want to double book over existing meetings on your calendar, like when you need to schedule a high-priority meeting or a job interview.",
        },
        {
          text: "You get two override options in Vimcal:",
        },
        {
          text: "Ignore all conflicts",
          isSubheader: true
        },
        {
          text: "Toggle this on if you want the booking link to disregard all conflicts on your calendar and always show all the selected Slots to the recipient.",
        },
        {
          text: "Pro Tip: Reserve this for the highest priority meetings to prevent accidental double bookings.",
        },
        {
          text: "Ignore my internal conflicts",
          isSubheader: true
        },
        {
          text: "Toggle this on to allow recipients to book over meetings you define as 'interal'.",
        },
        {
          text: "Pro Tip: Set what company domains or individual email addresses count as an internal meeting in your General Settings.",
        },
        {
          text: "--- ADD LINK TO SECTION OF DOCS ARTICLE FOR INTERNAL DOMAINS. NAME THIS LINK 'View Internal Meeting Settings'",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: implement
        },
      ],
    },
    {
      header: "Custom Questions",
      bodies: [
        {
          text: "Add additional fields in the booking link to collect additional relevant information for your meeting Slots.",
        },
        {
          text: "Some examples include:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Company name",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Phone Number",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Reason for Call",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Any other question of your choosing",
              isIndented: true,
            },
          ],
        },
        {
          text: "You may set Custom Questions as optional or required.",
        },
        {
          text: "--- IMAGE OF CUSTOM QUESTIONS IN BOTH THE SETTING AND LIVE LINK",
        },
      ],
    },
  ],
};

const GROUP_VOTE_SUBSECTION = {
  name: "Group Vote",
  path: CONTENT_PATH.GROUP_VOTE,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "‘Group Vote’ allows you to easily find the best meeting time that works for everyone across a large group.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        WHERE_TO_FIND_CONTENT,
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "The circular, light green button at the bottom right of your calendar → ‘Group Vote’ in dropdown",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: `‘A’ on your keyboard  → ‘Group Vote’ in dropdown`,
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Cmd + K (Mac) → or Ctrl + K (PC) and type “Vote” → “View Group Vote links”",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Small icon at the very top left of your calendar, above the time zone (then under ‘Availability Features’)",
              isIndented: true,
            },
          ],
        },
      ],
    },
    {
      header: "Popular Use Case",
      bodies: [
        {
          type: CONTENT_TYPE.TEXT,
          text: "Agreeing on time & day for a company off-site",
          isSubheader: true,
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Open the ‘Group Vote’ feature",
            },
            {
              text: "Step 2: Select ‘+ Create’ in the top right of the righthand panel",
            },
            {
              text: "Step 3: Click & drag times you’d like to have as options for voting",
            },
            {
              text: "Step 4: Ensure all settings on the righthand panel are correct",
            },
            {
              text: "Step 5: Either “Share via email” and enter colleagues’ email addresses to Send, or ‘Copy link’ and paste wherever you’d prefer (Slack, Whatsapp, etc)",
            },
            {
              text: "Step 6: Once the Group Vote has been answered by all recipients, re-open the Group Vote’ feature and select ‘+ Create event’",
            },
          ],
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Step 6: ‘Create’ event and confirm in the Create Event page.",
        },
        {
          text: "Once created, all voters/attendees will receive an email confirming the created event.",
        },
      ],
    },
  ],
};
const TEMPLATES_SUBSECTION = {
  name: "Templates & Sticky Notes",
  path: CONTENT_PATH.TEMPLATES,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Whether you're scheduling onboarding calls, in-office interviews, or need to add your signature and important links to events — Event Templates and Sticky Notes eliminate repetitive typing and help you create events faster."
        },
      ],
    },
    {
      header: "Event Templates",
      bodies: [
        {
          text: "Instead of manually entering the same details each time, you can create an Event Template to save time.",
        },
        HOW_TO_CREATE,
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: `Press <strong>Cmd + K</strong> (Mac) or <strong>Ctrl + K</strong> (PC), then type <strong>"Create event template"</strong>.`,
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Fill out all the constant details, like the event title, duration, and location.",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Additionally, you can also create a template from an existing event. ",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Click on the event",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Click the vertical ellipsis.",
              isIndented: true,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Select “Create template from event”",
              isIndented: true,
            },
          ],
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/create+template+from+event.png",
        },
        SEE_IN_ACTION,
        {
          text: "Let’s say you have an in-person interview. Instead of entering the details each time you’re conducting one, simply create an event template for it!"
        },
        {
          text: "Head over to the Command Center via <strong>Cmd + K</strong> (Mac) or <strong>Ctrl + K</strong> (PC) and search for “Create event template.”"
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Cmd+center+template.png"
        },
        {
          text: "Add your event title, duration, and office address. You can also include directions in the event description."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Template+copy.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "Click <strong>“Create Template”</strong> and that’s it!"
        },
        {
          type: CONTENT_TYPE.TEXT,
          text: "Using an Event Template",
          isSubheader: true,
        },
        {
          text: "Press Cmd; (Mac) or Ctrl; (PC) to access your templates (and Sticky Notes – more on that below). Don't worry – when using a template, we'll only update empty fields to preserve any changes you've made if you already have the event form up. "
        },
        {
          text: "The cool part is you can also use the template when creating your Slots booking! Just open Slots by pressing A and Cmd; (Mac) or Ctrl; (PC) to apply the template."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Template+%2B+Slots.png",
          type: CONTENT_TYPE.IMAGE,
        }
      ],
    },
    {
      header: "Sticky Notes",
      bodies: [
        {
          text: `Stickies are the perfect way to keep commonly used text snippets at your fingertips. They are also great when inviting guests to an event. Just pull up the sticky, and we'll paste the contents right into the email for you.`,
        },
        HOW_TO_CREATE,
        {
          text: "1. Press Cmd + K (Mac) or Ctrl + K (PC), then type “Create a Sticky”"
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Cmd+center+sticky.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "2. Add a title for your sticky note."
        },
        {
          text: "3. Add the text you want to save, like an email signature or meeting link."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Sticky+note.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "Access your Sticky Notes",
          isSubheader: true,
        },
        {
          text: "You can access Stickies the same way you would for Event Templates, by pressing <strong>Cmd;</strong> (on Mac) or <strong>Ctrl;</strong> (on PC). When you edit a specific field in an event and apply a Sticky, the text will be directly inserted into that field. For example, you can paste an office address into the location field or event description."
        },
        {
          text: "If you're not focused on some text box within the Vimcal app, applying a Sticky will copy its content right to your clipboard. You can store important information in Stickies —like your LinkedIn URL, office address, signature, or phone number—and paste them anywhere you need, even outside of Vimcal!"
        }
      ],
    },
    {
      header: "Related Articles",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Contact Groups",
              link: getCompleteURL(CONTENT_PATH.CONTACT_GROUPS),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Personal Links",
              link: getCompleteURL(CONTENT_PATH.PERSONAL_LINKS),
            },
          ],
        },
      ],
    },
  ],
};

const GROUP_VOTE_SPREADSHEET_SUBSECTION = {
  name: "Group Spreadsheet",
  path: CONTENT_PATH.GROUP_VOTE_SPREADSHEET,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "The Group Votes Spreadsheet streamlines meeting coordination for executives by letting their EAs vote on proposed meeting times and add context in a live, collaborative document. ",
        },
        {
          text: "No more endless email chains — just quick, transparent, real-time updates.",
        },
      ],
    },
    {
      header: "How to set it up",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Open Group Vote:",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              format: TEXT_FORMAT.BOLD,
            },
            {
              text: `Click "Group Polls" under "Quick Actions" on the right-hand side of the home page. `,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: "Create a Spreadsheet:",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              format: TEXT_FORMAT.BOLD,
            },
            {
              text: `Click ‘+ Create’ in the right panel.`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: `Choose "Create new spreadsheet" under the Group Spreadsheet option.`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: "Offering times:",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              format: TEXT_FORMAT.BOLD,
            },
            {
              text: `Select and drag your preferred time slots on the calendar.`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: `Pro-tip: When you invite co-workers, their calendars overlay with yours, making it easier to spot and select mutually available times.`,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: "Confirm invite details:",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              format: TEXT_FORMAT.BOLD,
            },
            {
              text: "Title",
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: "Duration",
              listType: LIST_TYPE.BULLET_INDENTED,
            }, 
            {
              text: "Attendees",
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: "Conferencing link",
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
      ],
    },
    {
      header: "Sharing and Voting",
      bodies: [
        {
          text: `Once you’ve saved the Group Spreadsheet, copy the link to distribute through Slack, email, WhatsApp, etc.`,
        },
        {
          text: `EAs can vote on available times on behalf of their executives, add notes for context, or add more executives to the document.`,
        },
        {
          text: `Anyone with the link can access it, even non-Vimcal users.`
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Group+Vote+Spreadsheet+LR.png"
        },
      ],
    },
    {
      header: "Transforming Votes into a Meeting",
      bodies: [
        {
          text: `Once all votes are gathered, revisit the Group Vote feature by clicking “Group Polls” under “Quick Actions”, select the '<strong>Create event</strong> button,' and verify the details. `,
        },
        {
          text: "This time-saving feature instantly creates the meeting and automatically sends email confirmations to all participants, allowing EAs to focus on more important tasks."
        },
        {
          text: "With just one click, Vimcal streamlines the entire process, eliminating the need for manual vote tallying and individual invitations. "
        }
      ],
    }
  ],
};

const FOCUS_MODE_SUBSECTION = {
  name: "Focus Mode",
  path: CONTENT_PATH.FOCUS_MODE,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Focus Mode offers a distraction-free, full-page overlay designed to enhance productivity and deep work. ",
        },
        {
          text: `You can quickly access it by pressing <strong>'0'</strong> or opening the Command Center with <strong>CMD + K</strong> and then selecting <strong>"Focus mode"</strong>.`,
        },
      ],
    },
    {
      header: "Pomodoro Timer",
      bodies: [
        {
          text: "Stay productive with our Pomodoro timer. You can switch between 25-minute focus and 5-minute breaks, or click the dropdown for longer sessions (45-minute focus, 15-minute breaks).",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Focus+mode/pomodoro+timer.png",
        },
      ],
    },
    {
      header: "Vibes",
      bodies: [
        {
          text: "For the perfect deep work soundscape, click the headphones icon (Vibes) in the lower-right corner. You can choose from a variety of ambient sounds to boost your focus.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Focus+mode/Vibes.png",
        }
      ],
    },
    {
      header: "Calendar Preview",
      bodies: [
        {
          text: "Your upcoming events are conveniently displayed in the upper-right corner for a quick glance for easy access to your next call.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Focus+mode/Focus+Mode.png",
        }
      ],
    }
  ],
};
const TAGS_METRICS_SUBSECTION = {
  name: "Tags and metrics",
  path: CONTENT_PATH.TAGS_AND_METRICS,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Vimcal lets you automatically tag and color code any event so you can quickly visualize how your week is broken down.",
        },
        {
          text: "To label or tag specific events, simply click or focus on the event and press 'P,' or right-click on the event to access the tags feature. From there, create a tag and assign it a color for easy identification.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "To automatically assign tags to specific events, navigate to the Command Center (press Command + K for Mac or Control + K for PC) and select 'Update Tag Settings'.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: `Click "Create new tag"`,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Assign a color, add a label, and establish rules to ensure the tag applies to events that meet specific criteria.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: `Once done, just hit "Save."`,
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Weekly metrics emails will provide a breakdown of the following:",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Internal vs external meetings each week",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Swiss cheese time in your calendar",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Hour or percentage breakdown of the tags you've created within Vimcal",
            },
          ],
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
  ],
};

const TIME_TRAVEL_SUBSECTION = {
  name: "Time Travel",
  path: CONTENT_PATH.TIME_TRAVEL,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Time Travel is the feature that makes converting up to 5 time zones at once a breeze.",
        },
        {
          text: "It's the second most popular feature in Vimcal after Slots. Our goal is to make sure you never have to do time zone mental math ever again.",
        },
        WHERE_TO_FIND_CONTENT,
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "The time zone at the very top left of your Vimcal calendar",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: `‘Z’ on your keyboard`,
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Cmd + K (Mac) or Ctrl + K (PC)→ type “Time Travel”",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Or click on the top right section of the Slots panel labeled “Change time zone”",
            },
          ],
        },
      ],
    },
    {
      header: "Looking up time zones",
      bodies: [
        {
          text: "You often know someone's city but not their time zone.",
        },
        {
          text: "To save you a few Google searches, we took the extra step to let you search by city, country, or time zone when you Time Travel!",
        },
        {
          text: "If a country has multiple time zones, we will suggest them all to you. Daylight savings is also automatically accounted for you.",
        },
      ],
    },
    {
      header: "Overlapping Work Hours ",
      bodies: [
        {
          text: "Keeping meetings and other events within work hours is crucial. Quickly setup your preferred working hours in Vimcal ",
        },
        {
          text: "How to Setup: ",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Press Cmd + K (Mac) or Ctrl + K (PC) and type in settings",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "In the General section, there should be a small sub-section labeled Display.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "Here you can adjust your start and end work hours ",
            },
          ],
        },
        {
          text: "Note: This will only display when using the time travel feature!",
        },
      ],
    },
    {
      header: "Slots + Time Travel",
      bodies: [
        {
          text: "Vimcal features are designed to work in perfect harmony with each other. ",
        },
        {
          text: "Combining Slots and Time travel allows each meeting participant to see the proposed times from their prospective timezone when initially viewing the email and again when they select a time! ",
        },
      ],
    },
  ],
};

const TIME_ZONE_SUBSECTION = {
  name: "Time Zones",
  path: CONTENT_PATH.TIME_ZONES,
  topics: [
    {
      header: "Slots + Time Travel ",
      bodies: [
        {
          text: "Vimcal features are designed to work in perfect harmony with each other.",
        },
        {
          text: "Combining Slots and Time travel allows each meeting participant to see the proposed times from their perspective timezone when initially viewing the email and again when they select a time! ",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Access the Availability feature & choose ‘Slots’ from the dropdown",
            },
            {
              text: "Step 2: Click & drag desired Slots on the calendar",
            },
            {
              text: "Step 3: Time Travel by selecting ‘Change time zone’ at the top right of the righthand panel",
            },
            {
              text: "Step 4: Enter recipient’s city or time zone",
            },
            {
              text: "Step 5: Copy to clipboard via Cmd + C (Mac) , Ctrl + C (PC) or by selecting the blue button at the bottom of the righthand panel",
            },
            {
              text: "Step 6: Paste",
            },
          ],
        },
        {
          text: "The order (Slots → Time Travel, Time Travel → Slots) doesn’t matter, though you may prefer one vs. another depending on where your recipient is located. If you’re in vastly different timezones, consider Time Traveling and then choosing Slots.",
        },
        {
          text: "This will ensure you’re selecting appropriate times for both parties, right from the start.",
        },
      ],
    },
    {
      header: "Considering 3+ time zones for more people",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Repeat steps 1-4 above",
            },
            { text: "Step 2: Press ‘Z’ to open up Time Travel" },
            {
              text: "Step 3: Enter another city or time zone",
            },
            { text: "Step 4: Do this up to four times, totaling 5 time zones" },
          ],
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "The large, darker area in the middle between the two, lined areas represents a rough estimate of when all meeting participants might be awake (7am-7pm).",
        },
        {
          text: "Using the single quotation key to the left of ‘return’ on the keyboard will allow you to toggle between the selected time zones. This is useful for gaining a different vantage point and for personalizing your proposed Slots.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Anchoring Time zone",
      bodies: [
        {
          text: "Always needing to have an executive’s time zone handy",
          isSubheader: true,
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Press Cmd + K (Mac) or Ctrl + K (PC) to open the Command Center",
            },
            { text: "Step 2: Type ‘anchor’ or ‘time’ " },
            {
              text: "Step 3: Select ‘Update anchor time zones’",
            },
            { imageSrc: "" }, // TODO: add
            {
              text: "Step 4: Add the time zones you’d like to always have in Vimcal",
            },
            { text: "Step 5: Save" },
          ],
        },
      ],
    },
  ],
};

const EXTERNAL_SCHEDULING_SUBSECTION = {
  name: "External Scheduling",
  path: CONTENT_PATH.EXTERNAL_SCHEDULING,
  topics: [
    {
      header: "Find a time to meet with an external party (in person, virtual)",
      bodies: [
        {
          text: "The most consistent workflow in Vimcal is sharing times with external parties (investors, clients, friends). ",
        },
        {
          text: "Here are steps on how to efficiently share your availability! ",
        },
        {
          text: `Step 1: Access the availability feature & choose ‘Slots’ from the dropdown`,
        },
        {
          text: `Step 2: Click & drag desired Slots on the calendar`,
        },
        {
          text: `Bonus: Underneath ‘Invite Details’ is a property called ‘Conferencing’ where you can choose the nature of your meeting (virtual, in person).`,
        },
        {
          text: "If you’d like to add a physical location, expand ‘+ Personalize invite’ to populate the ‘Where’ field.",
        },
        {
          text: "Step 3: Copy to clipboard via Cmd + C (Mac), Ctrl + C (PC), or by selecting the blue button at the bottom of the righthand panel",
        },
        {
          text: "Step 4: Paste",
        },
      ],
    },
    {
      header: "Send times to someone in a different time zone",
      bodies: [
        {
          text: "Now a days everyone is either working or meeting with someone in a different timezone. In Vimcal, we make this process simple! ",
        },
        {
          text: "No more mental math! ",
        },
        {
          text: "Step 1: Access the availability feature & choose ‘Slots’ from the dropdown & Click/drag desired Slots on the calendar",
        },
        {
          text: "Step 2: Time Travel by selecting ‘Change time zone’ at the top right of the righthand panel",
        },
        {
          text: "Step 3: Enter recipient’s city or time zone",
        },
        {
          text: "The order (Slots → Time Travel, Time Travel → Slots) doesn’t matter, though you may prefer one vs. another depending on where your recipient is located. If you’re in vastly different timezones, consider Time Traveling and then choosing Slots.",
        },
        {
          text: "This will ensure you’re selecting appropriate times for both parties, right from the start.",
        },
        {
          text: "Step 4: Copy to clipboard via Cmd + C (Mac), Ctrl + C (PC) or by selecting the blue button at the bottom of the righthand panel",
        },
        {
          text: "Step 5: Paste",
        },
      ],
    },
    {
      header: "Scheduling with a Group ",
      bodies: [
        {
          text: "Have you ever tried to coordinate the best time between 10+ ppl for a board meeting/offsite/bday party? Its tough. ",
        },
        {
          text: "Group Vote Poll’s is the cleanest way to ensure that everyone can provide input on the best time ",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Open the ‘Group Vote’ feature",
            },
            {
              text: "Step 2: Select ‘+ Create’ in the top right of the righthand panel",
            },
            {
              text: "Step 3: Click & drag times you’d like to have as options for voting",
            },
            {
              text: "Step 4: Ensure all settings on the righthand panel are correct",
            },
            {
              text: "Step 5: Either “Share via email” and enter colleagues’ email addresses to Send, or ‘Copy link’ and paste wherever you’d prefer (Slack, Whatsapp, etc)",
            },
            {
              text: "Step 6: Once the Group Vote has been answered by all recipients, re-open the Group Vote’ feature and select ‘+ Create event’",
            },
            {
              text: "Step 7: ‘Create’ event and confirm in the Create Event page.",
            },
          ],
        },
        {
          text: "Once created, all voters/attendees will receive an email confirming the created event.",
        },
      ],
    },
    {
      header: "Free Time Finder ",
      bodies: [
        {
          text: "Free time finder comes in handy when you have a packed week and you don’t want to look for opening in your calendar. ",
        },
      ],
    },
    {
      header: "Find Times",
      bodies: [
        {
          text: "By clicking the Find times feature, Vimcal will quickly display the open slots that work for you. ",
        },
        {
          text: "Bonus: If you need to change the range of times that were selected, we offer different options to choose from! ",
        },
      ],
    },
    {
      header: "Related Articles",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Slots",
              link: getSlotsContentURL(),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Time Travel",
              link: getCompleteURL(CONTENT_PATH.TIME_TRAVEL),
            },
          ],
        },
      ],
    },
  ],
};

const INTERNAL_SCHEDULING_SUBSECTION = {
  name: "Internal Scheduling",
  path: CONTENT_PATH.INTERNAL_SCHEDULING,
  topics: [
    {
      header: "Meet With",
      bodies: [
        {
          text: `In Vimcal users can quickly overlay co-worker’s calendars using Meet with. `,
        },
        {
          text: "Meet with makes it easy to: ",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Loosely overlay your co-worker’s calendar ",
            },
            { text: "Schedule an event with that teammate(s)" },
            {
              text: "Propose potential meeting times that work for yourself and a teammate or group (Slots) ",
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Step 1: Press Cmd + J (Mac) or Ctrl + J (PC)",
            },
            { text: "Step 2: Type your colleague’s name" },
            {
              text: "Step 3: Select an open time on the calendar",
            },
            {
              text: "Step 4: Create event",
            },
          ],
        },
        {
          text: "After the event is created, you’ll notice the other person’s calendar will disappear. Cmd + J (Mac) or Ctrl + J (PC) brings up calendars temporarily and will automatically add the other person as an attendee to subsequently created events (rather than manually toggling on a calendar from the bottom right).",
        },
        {
          text: `You can quickly clear all the calendars by hitting the "Esc" key.`,
        },
      ],
    },
    {
      header: "Popular Use cases:",
      bodies: [
        {
          text: "Finding Mutually Free Times with Coworkers",
          isSubheader: true,
        },
        {
          text: "Find Meeting times is a game changer when your co-worker asks you to just schedule any open time for a meeting on their calendar. ",
        },
        {
          text: "When creating that meeting or using the Meet with feature, clicking the meeting option under your teammates name will display the open slots that work for both you and your co-workers' calendars. ",
        },
        {
          text: "Note: That teammate will need to be associated with your company domain in Google or Outlook. ",
        },
      ],
    },
    {
      header: "Contact Groups",
      bodies: [
        {
          text: "If you’re constantly working with different teams with your company or just the same group of people, Contacts groups is a must! ",
        },
        {
          text: "These Groups allow you to bundle certain people together on your team for a variety of uses.",
        },
        {
          text: "Quickly look at Engineering’s calendar",
          isSubheader: true,
        },
        {
          text: "In this example, we’ll create a group for the engineering team! ",
        },
        {
          text: "Step 1: Press Cmd + J (Mac) or Ctrl + J (PC).",
        },
        {
          text: "Step 2: Type “Eng”",
        },
        {
          text: "Again, you’ll now be temporarily viewing everyone calendar who you’ve added to the “Eng team” contact group (as noted by the blue bar at the top):",
        },
      ],
    },
  ],
};

const KEYBOARD_SHORTCUTS_SUBSECTION = {
  name: "Keyboard Shortcuts",
  path: CONTENT_PATH.KEYBOARD_SHORTCUTS,
  topics: [
    {
      header: "Flash Shortcuts",
      bodies: [
        {
          text: "Press the 'F' key to display an overlay of hotkeys next to each feature.",
        },
        {
          text: "This is great for visual learners and those who want to learn the shortcuts at your own pace!",
        },
        {
          text: "Pro Tip: For advanced shortcut users, we also apply a semi-random shortcut to each event on your calendar.",
        },
        {
          text: "--- IMAGE OF FLASHING HOTKEYS",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "View all shortcuts",
      bodies: [
        {
          text: "For a full list of all the keyboard shortcuts in Vimcal, please reference our .",
        },
        {
          text: "You can access it simply by typing '?'.",
        },
        {
          text: "There's also a search interface for yout o quickly query the hotkey you're looking for.",
        },
        {
          text: "--- IMAGE OF SHORTCUTS MENU",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Command Center",
      bodies: [
        {
          text: "The Command Center is Vimcal's central hub where you can access every feature and setting.",
        },
        {
          text: "Access it by hitting Cmd + K (Mac) or Ctrl + K (PC).",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "--- ADD LINK TO COMMAND CENTER DOC WITH TEXT 'Learn more about the Command Center here.'",
        },
      ],
    },
  ],
};

const WEEKLY_METRICS_SUBSECTION = {
  name: "Metrics Report",
  path: CONTENT_PATH.WEEKLY_METRICS,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "The metrics report is generated each week based on your tagged events. This lets you quickly scan your week and instantly understand:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Which types of activities consume most of your time",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "How you're balancing work, personal, and professional commitments",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Patterns in your productivity",
            }
          ],
        },
        {
          text: "Each weekly report gives you a clear view of your calendar, breaking down your time by:"
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Meeting types",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Internal vs. external interactions",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Other Categories",
            }
          ],
        }
      ],
    },
    {
      header: "Accessing Your Metrics",
      bodies: [
        {
          text: "Search “Metrics” in the Command Center to view the report at any time."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Metrics.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "You can also set up weekly email notifications to receive your metrics report directly in your inbox."
        }, 
      ]
    },
    {
      header: "Your Weekly Metrics report shows how you're spending your days. ",
      bodies: [
        {
          text: "Your Weekly Metrics report shows how you're spending your days. "
        },
        {
          text: "Effortlessly toggle between your personal Weekly Metrics report and your executive's insights with a single drop-down menu. " // TODO: remove for core Vimcal
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Metrics+per+exec.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "You can also navigate through your time insights with ease. A simple drop-down menu at the top of the report page lets you seamlessly switch between this week's breakdown and historical reports from previous weeks and months."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Reference+previous+Metrics.png",
          type: CONTENT_TYPE.IMAGE,
        }
      ]
    },
    {
      header: "Meeting Breakdown",
      bodies: [
        {
          text: "Get a comprehensive breakdown of your meetings:"
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "<strong>Daily Average:</strong> Your typical meeting time per day.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "<strong>Meeting Hours per Week:</strong> Total hours spent in meetings.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "<strong>Total Meetings:</strong> Number of meetings this week.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "<strong>Busiest Day:</strong> The day with the most meeting activity.",
            },
          ],
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Weekly+meeting+breakdown.png",
          type: CONTENT_TYPE.IMAGE,
        }
      ]
    },
    {
      header: "Interactive Meeting Graph",
      bodies: [
        {
          text: "Customize your view with an interactive graph that adapts to your preferences. "
        },
        {
          text: "Simply check or uncheck tags to filter your meeting data instantly and watch the graph transform in real-time."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Meeting+breakdown+graph.png",
          type: CONTENT_TYPE.IMAGE,
        }
      ]
    },
    {
      header: "Internal vs. External Time Allocation",
      bodies: [
        {
          text: "The pie chart offers a visual breakdown of how you're dividing your meeting time:"
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Internal meetings with team members.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "External meetings with clients or partners.",
            },
          ],
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Pie+chart+internal+%26+external.png",
          type: CONTENT_TYPE.IMAGE,
        },
      ]
    },
    {
      header: "Swiss Cheese Time",
      bodies: [
        {
          text: "The Swiss Cheese Time section reveals productivity leaks caused by gaps of 30 minutes or less between meetings. "
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Swiss+cheese+time.png",
          type: CONTENT_TYPE.IMAGE,
        }
      ]
    },
    {
      header: "People you met with over the last week ",
      bodies: [
        {
          text: "A comprehensive list of your connections from the past week. This section provides a quick overview of your interactions, showing:"
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Who you've connected with",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Number of meetings per person",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Breakdown of internal and external contacts",
            }
          ],
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/People+you've+met+with.png",
          type: CONTENT_TYPE.IMAGE,
        }
      ]
    }
  ],
};

// TODO: also use this section for core Vimcal
const VIMCAL_EA_COLOR_CODING_AND_TAGGING_SUBSECTION = {
  name: "Color Coding and Tagging",
  path: CONTENT_PATH.COLOR_CODING_AND_TAGGING_VIMCAL_EA,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Transform your calendar from a simple schedule into a powerful productivity tool with our Tags feature."
        },
        {
          text: "By visually distinguishing your events and categorizing them, you can instantly grasp your day's structure, track how you spend your time, and make data-driven decisions about your schedule."
        },
        getSubtitleBody("How to createTags"),
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "1. Go to the <strong>Command Center</strong> (<strong>Cmd + K</strong> for Mac or <strong>Ctrl + K</strong> for PC) <strong>Update Tag Settings</strong>.",
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "2. Click Create new tag or Create your first tag.",
            },
          ],
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Internal+%26+external+tags.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "3. You can create separate tags for each Executive you manage through the drop-down.",
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Tags+per+profile.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "4. You can also create tags for each of your clients.",
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/tag+event.png",
          type: CONTENT_TYPE.IMAGE,
        } 
      ],
    },
    {
      header: "Auto-tags",
      bodies: [
        {
          text: "Tags can also be automatically applied on your behalf. ",
        },
        {
          text: "Simply set some rules and watch your calendar organize itself."
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "1. Follow Steps 1 and 2 above when creating new tags.",
            },
            {
              listType: LIST_TYPE.BULLET_INDENTED,
              text: "2. Select <strong>Add auto-tagging</strong>.",
            },
          ],
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Screenshot+2025-02-28+at+12.54.11%E2%80%AFPM.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "3. You can configure how tags are assigned using different rules."
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Auto+tagging+rules.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Auto+tagging.png",
          type: CONTENT_TYPE.IMAGE,
        }
      ]
    },
    {
      header: "Special tags",
      bodies: [
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Special+tags.png",
          type: CONTENT_TYPE.IMAGE,
        },
        {
          text: "Special Tags are a select few Vimcal default Tags with special rules."
        },
        {
          text: "Just toggle on these ready-to-use smart tags and get instant insights into how you're spending your time."
        },
        getSubtitleBody("Internal tags"),
        {
          text: "When enabled, an “Internal” tag will automatically be added to any meeting where 100% of the attendees are from your company. "
        },
        {
          text: "These tags will tell you:"
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "How much time you spend collaborating with team members",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "The proportion of internal versus external interactions",
            },
          ],
        },
        {
          text: "You can define which domains or emails constitute an internal meeting by following the instructions under the <strong>Ignore internal conflicts</strong> section.",
          link: getCompleteURL(CONTENT_PATH.SLOTS),
        },
        getSubtitleBody("External tags"),
        {
          text: "External tags highlight meetings with people outside your organization. "
        },
        {
          text: "These tags help you:"
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Track client-facing time",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Monitor investor or partner meetings",
            },
          ],
        },
        getSubtitleBody("Solo tags"),
        {
          text: "They mark events with no other attendees — your personal time blocks, deep work sessions, reminders, or individual activities. "
        },
        {
          text: "With these tags, you can:"
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Visualize dedicated personal or focus time",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Ensure you're protecting space for individual work and self-care",
            },
          ],
        },
      ],
    },
    {
      header: "Visibility for Executives and Assistants",
      bodies: [
        {
          text: "If your executive is also a Vimcal user, their tags can be shared with you if they grant you delegate access to their Vimcal account. "
        },
        {
          text: "This seamless sharing ensures that both the executive and their assistant have a comprehensive, synchronized view of the calendar."
        }
      ]
    },
  ],
};

const COLOR_CODING_AND_TAGGING_SUBSECTION = {
  name: "Color Coding and Tagging",
  path: CONTENT_PATH.COLOR_CODING_AND_TAGGING,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Vimcal lets you automatically tag and color code any event so you can quickly visualize how your week is broken down.",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "To label or tag specific events, simply click or focus on the event and press 'P,' or right-click on the event to access the tags feature. From there, create a tag and assign it a color for easy identification.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "To automatically assign tags to specific events, navigate to the Command Center (press Cmd + K (Mac) or Ctrl + K (PC) and select 'Update Tag Settings'.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: `Click "Create new tag"`,
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: "Assign a color, add a label, and establish rules to ensure the tag applies to events that meet specific criteria.",
            },
            {
              listType: LIST_TYPE.BULLET_NON_INDENTED,
              text: `Once done, just hit "Save."`,
            },
          ],
        },
      ],
    },
  ],
};

const FREE_TIME_FINDER_SUBSECTION = {
  name: "Free Time Finder",
  path: CONTENT_PATH.FREE_TIME_FINDER,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "Free Time Finder is a feature that lets you easily convert times that other people send you into slots on your calendar, allowing you to instantly see which times work best.",
        },
      ],
    },
    {
      header: "How it works",
      bodies: [
        {
          text: "Free Time Finder can be launched in one of three ways:"
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Paste the text from your conversation that contains the suggested times directly into the Vimcal app with <strong>Cmd + V</strong> (Mac) or <strong>Ctrl + V</strong> (PC)",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
            },
            {
              text: "Dragging an image or screenshot of your conversation with the times directly into the Vimcal app",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
            },
            {
              text: "Pasting a Calendly or Vimcal booking link with  <strong>Cmd + V</strong> (Mac) or <strong>Ctrl + V</strong> (PC)",
              listType: LIST_TYPE.BULLET_NON_INDENTED,
            },
          ],
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Drag+%3A+Select+image+FTF.png"
        },
        {
          text: "Free Time Finder instantly highlights available slots on your calendar, making it easy to see which times work best with your schedule."
        }
      ]
    },
    {
      header: "Bring Your Event to Life",
      bodies: [
        {
          text: "It's simple: Click any open time slot to create your event instantly",
        },
        {
          text: "Can't make it work? No problem! Create a Slots booking link to share your available times instead—you'll find this option right in the side panel. Your attendees can then choose from your preferred time slots, making scheduling effortless for everyone.",
        },
        {
          src: "https://vimcal-help-center-assets.s3.us-east-1.amazonaws.com/Vimcal+EA+Help+docs/Send+Slots+back.png",
          type: CONTENT_TYPE.IMAGE,
        }
      ],
    },
    {
      header: "Related Articles",
      bodies: [
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Slots",
              link: getSlotsContentURL(),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Color coding and Tagging",
              link: getTaggingContentURL(),
            },
            {
              listType: LIST_TYPE.DEFAULT,
              text: "Creating events with NLP",
              link: getCompleteURL(CONTENT_PATH.NLP),
            },
          ],
        },
      ],
    },
  ],
};

const FAQ_SUBSECTION = {
  hideChapters: true,
  name: "Frequently asked questions",
  path: CONTENT_PATH.FAQ,
  topics: [
    {
      header: "Can I have multiple accounts?",
      bodies: [
        {
          text: "Easily add additional accounts to Vimcal by pressing command + K / Ctrl + K (for Windows) and typing out add account. ",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Can I see all my calendars in one view?",
      bodies: [
        {
          text: "Yes! After adding an account to Vimcal, you can easily toggle on your preferred calendar view under the calendar list in the lower left-hand side panel. ",
        },
      ],
    },
    {
      header: "Do you have a desktop app?",
      bodies: [
        {
          text: "Yes! To download the desktop app, users can press command + K and type download.",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Note:",
            },
            { text: "Apple M1, M2, M3 - Apple Silicon", isIndented: true },
            { text: "Other Mac versions & builds - Intel", isIndented: true },
            { imageSrc: "" }, // TODO: add
          ],
        },
      ],
    },
    {
      header: "Do the booking links update automatically?",
      bodies: [{ text: "Yes!" }],
    },
    {
      header: "Can I add multiple time zones to Time Travel?",
      bodies: [{ text: "Yes!" }],
    },
    {
      header:
        "Can I use a different anchor time zone for Time Travel? How many time zones can I overlay at one time?",
      bodies: [
        {
          text: "Absolutely! You can add and view up to 5 time zones  using Time Travel.",
        },
      ],
    },
    {
      header: "What are “tags”?",
      bodies: [{ text: "Tags let you assign labels and colors to an event." }],
    },
    {
      header: "Why should I use tags?",
      bodies: [
        {
          text: "We recommend using tags so you can quickly see how your week is broken down and improve your productivity.",
        },
      ],
    },
    {
      header: "Can I duplicate an event?",
      bodies: [
        {
          text: "Sure! You can easily duplicate an event by going to the Command Center via ⌘ + K (Ctrl + K on a PC) > search for “Duplicate”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "You can also do this by clicking the event > type “YP” > then “Create Event” to duplicate it.",
        },
        {
          text: "Another option is to click the event > go to the menu (3 dots) > click on Duplicate",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "How do I copy an event to a different calendar?",
      bodies: [
        {
          text: "You can copy an event by going to the Command Center via ⌘ + K (Ctrl + K on a PC) > search for “Copy to email address” > Create Event and you’re all set!",
        },
      ],
    },
    {
      header:
        "I don’t want to see the weekends on my calendar. How do I hide them?",
      bodies: [
        {
          text: "You can hide them via the Command Center ⌘ + K (Ctrl + K on a PC) > search for “weekend” ",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Can I search other calendars other than my primary calendar?",
      bodies: [{ text: "Yes!" }],
    },
    {
      header: "How do I search for an event?",
      bodies: [{ text: `Yes! Press “/” then search for the event title.` }],
    },
    {
      header: "Do you have a mobile app?",
      bodies: [
        {
          text: "Yes, we do! To download the mobile app, users can press ⌘ + K (Ctrl + K on a PC) and type download. ",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "What are slots?",
      bodies: [
        {
          text: "Slots enable you to create a booking link, allowing you to easily share your availability with others.",
        },
      ],
    },
    {
      header:
        "Can I customize the text that populates for Slots? If so, where can I find it?",
      bodies: [
        {
          text: "Definitely! Simply click the gear icon for Slots and choose if you want to modify copy for Hyperlinked Text, Plaintext + URL, or Plaintext only.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "You can modify the Pre-slots copy (and Link copy for Plaintext + URL) then hit “Save” to apply the changes.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "How do I change the times I’m available for a Personal Link?",
      bodies: [
        {
          text: "Press Shift + Y to pull up Personal Link and click the pencil icon right next to the link you want to modify.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "You’ll see this page where you can simply select, remove, or drag time blocks depending on your availability.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "What’s the difference between Slots and Personal Links?",
      bodies: [
        {
          text: "With Slots, you just drag the times you want to offer someone and we'll type up a personalized message (with time zone & link) for you to easily copy. This expires after it’s past the specific dates and times that were offered.",
        },
        {
          text: "On the other hand, Personal Links are static booking links used for ongoing availability.",
        },
      ],
    },
    {
      header: "What are event templates?",
      bodies: [
        {
          text: `Instead of manually entering the same details for your events, you can simply create a template! This saves you time when you have to create certain events over and over again. Simply Hit "Command K" -> "template" and select "Create event template."`,
        },
      ],
    },
    {
      header: "What’s a sticky?",
      bodies: [
        {
          text: "If you want to save notes, an email template, or anything that you'd like to quickly copy and paste as you create your events then you can make use of Sticky. ",
        },
        {
          text: "You can create a Sticky by going to the Command Center and type “Create a Sticky.”",
        },
        {
          text: `To use or modify your sticky notes, just go back to the Command Center and search for "Go to templates" to edit them or "Use Templates" to apply them. `,
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Can I view all the shortcuts in the app somewhere?",
      bodies: [
        {
          text: "Yes! Just type “?” and it will display all the keyboard shortcuts.",
        },
      ],
    },
    {
      header: "What is “Launch and copy Zoom personal link?”",
      bodies: [
        {
          text: "This will open your Zoom Personal Meeting and copy the meeting link at the same time.",
        },
      ],
    },
    {
      header: "Can I create events with unique zoom links?",
      bodies: [
        {
          text: "Yes! Just head over to the Command Center and search for “Zoom Settings”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Make sure to toggle off “Default to using Personal Meeting Link” so it generates unique Zoom meeting links.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header:
        "How do I remove the “Created with Vimcal.com” text in the event description?",
      bodies: [
        {
          text: "You can remove the Vimcal signature by manually deleting it from the event description or you can permanently do so by hitting Command K > type “Remove Vimcal signature”",
        },
      ],
    },
    {
      header: "I want to receive desktop notifications. How do I turn them on?",
      bodies: [
        { text: "Go to the Command Center and search for “Up Next”" },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Make sure to tick the boxes of all the calendars you want to receive notifications for and hit “Save.”",
        },
        {
          text: "After that, click the Apple icon on the upper-left hand corner of your desktop and go to “System Settings:”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        { text: "Search for Notifications > Vimcal" },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Toggle on “Allow Notifications” and configure the rest of the settings shown below:",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "What is Group vote?",
      bodies: [
        {
          text: "Group vote allows you to find the best meeting time across a larger group where your guests/colleagues can place in votes for their preferred meeting time.",
        },
      ],
    },
    {
      header: "Can I open Gmail from Vimcal?",
      bodies: [
        { text: "Yes! Just search for “Open Gmail” in the Command Center." },
      ],
    },
    {
      header: "Can I open Google Calendar from Vimcal?",
      bodies: [
        {
          text: "Of course! Simply search for “Open Google Calendar” in the Command Center.",
        },
      ],
    },
    {
      header:
        "What’s the difference between the browser app and the desktop app?",
      bodies: [
        {
          text: "The browser app is when you use an internet browser like Chrome, Firefox, Safari, etc. ",
        },
        {
          text: "The desktop app is an actual application that you install on your computer (Windows/Mac). With the Mac desktop app you’ll also have a view of upcoming events within your Mac menu bar.  ",
        },
      ],
    },
    {
      header:
        "Is there a shortcut to switch between all the accounts I’m logged into?",
      bodies: [
        {
          text: "You can use “Ctrl + 1/2/3/…” to switch between your calendars accounts.",
        },
      ],
    },
    {
      header: "What’s the “Y1” shortcut for this Personal Link?",
      bodies: [
        {
          text: "Y1 is used to copy slots to your clipboard and you can paste or share it with your guests.",
        },
      ],
    },
    {
      header: "Can I embed my personal links on my website?",
      bodies: [
        {
          text: "Yes! Under the Personal Link you want to embed, click the drop-down for “Share” and select “Add to website.”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "How can I see another colleague’s calendar?",
      bodies: [
        {
          text: "You can press Command + J and search for your colleague to view their calendar.",
        },
      ],
    },
    {
      header:
        "Can Vimcal find probable meeting times that would work for both myself and my colleague(s) availability?",
      bodies: [
        {
          text: "Yes! While using the “Meet With” function, you’ll see the option for AI to find times for you using “Meeting” or “Slots” on the sidebar just below their name.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "How do I get Dark Mode to match my OS preference?",
      bodies: [
        {
          text: "You can search for “Dark mode” in Command Center and select “Match OS Color Scheme.”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Do you guys have a team plan?",
      bodies: [{ text: "We do!" }],
    },
    {
      header:
        "How does NLP work when creating an event? How does it detect what I want?",
      bodies: [
        {
          text: "The NLP parses details as you type and creates the event based on what was entered. ",
        },
        {
          text: "There's only one rule: start with the title of the event. After that, you can type out the details in any order. We detect natural language by keyword:",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Date and time (no keywords)",
              isSubheader: true,
            },
            { text: `"Coffee Wednesday at 3pm"` },
            {
              text: `"Coffee 11/3 4-6pm"`,
            },
            { text: `"Coffee tomorrow at noon"` },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"For" → duration`,
              isSubheader: true,
            },
            { text: `"Coffee for 45 minutes"` },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"With" → attendees`,
              isSubheader: true,
            },
            { text: `"Coffee with alex@vimcal.com"` },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `"At" → location`,
              isSubheader: true,
            },
            { text: `"Coffee at Four Barrel"` },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `Time zone`,
              isSubheader: true,
            },
            { text: `"Coffee at 3PM EST"` },
          ],
        },
        {
          text: "Default conferencing (no keywords) is automatically added when you invite someone else.",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: `Here are 2 examples combining everything. They have the same information but in different orders. The bolded text is the title.`,
            },
            {
              text: `"Coffee tomorrow at 5pm CST with alex@vimcal.com at Philz Coffee for 45 minutes"`,
              isIndented: true,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
            {
              text: `"Coffee at Philz Coffee with alex@vimcal.com for 45 minutes tomorrow at 5pm CST"`,
              isIndented: true,
              listType: LIST_TYPE.BULLET_INDENTED,
            },
          ],
        },
      ],
    },
    {
      header: "How do you select a different calendar through NLP?",
      bodies: [
        {
          text: "Use “/” and then select the calendar you want the event associated with.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "What calendars does the menu bar for the desktop app display?",
      bodies: [
        { text: "Follow up: Why is it not displaying my personal calendar?" },
      ],
    },
    {
      header:
        "For the menu bar, when I have multiple meetings that start at the same time and I hit cmd shift j, how does it know which meeting to join?",
      bodies: [
        {
          text: "After pressing Command + Shift + J, it will bring up this modal where you can select which meeting you’d like to join.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Why do you guys need all these permissions?",
      bodies: [
        {
          text: "Permissions are needed for you to get the full experience of the app. That way, it syncs with all your calendars",
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Why do you need domain users?",
            },
            {
              text: "The first permission is for Conference Rooms and the ability to overlay co-workers’ calendars within your organization ",
              isIndented: true,
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "Why do you need my contacts? Are you going to spam my contacts? ",
            },
            {
              text: "Autocomplete people’s emails - We don’t store their contacts. When they log out, the contacts are wiped from their browser",
              isIndented: true,
            },
          ],
        },
        {
          type: CONTENT_TYPE.LIST,
          items: [
            {
              text: "See, edit, share, and permanently delete all calendars you can access using Google Calendar. Are you going to “delete” all my calendar and my events?",
            },
            {
              text: "Read/Write - this looks scary but it’s the most basic calendar permission. This is for creating events, inviting people, RSVPing",
              isIndented: true,
            },
          ],
        },
      ],
    },
    {
      header: "How do I report bugs/send feedback?",
      bodies: [
        { text: "Press Command + K > “support” > choose from the selection." },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Can I try the app out for a week before paying?",
      bodies: [{ text: "Sure! You get a 7-day free trial upon signing up." }],
    },
    {
      header:
        "Can I change my events setting to always allow my guests to modify the event?",
      bodies: [
        {
          text: "Absolutely! Go to the Command Center via ⌘ + K (Ctrl + K on a PC) > search “General” > Event Permissions > tick the checkbox for Modify events.",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header:
        "Can I add someone’s email and name to availability slots to make it more personal?",
      bodies: [
        { text: "Yes! Select “Personalize Invite”" },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        { text: "Then, add the details for your guest." },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
    {
      header: "Can Slots account for my colleague’s availability?",
      bodies: [], // TODO: implement
    },
    {
      header:
        "When using Slots, am I able to add a scheduling buffer to the times I’m sending out?",
      bodies: [
        {
          text: "You can! Go to Slots (press “A”) > Click the gear icon > scroll down to “Scheduling Buffer.”",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
  ],
};

const EVENTS_SUBSECTION = {
  name: "Events",
  path: CONTENT_PATH.EVENTS,
  topics: [
    {
      header: "",
      bodies: [
        {
          text: "E - edit an event",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "R - reschedule an event",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "GE - email guest",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Use sticky notes templates",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "Command + O - Instant open",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
        {
          text: "GA - Edit Attendee list",
        },
        {
          type: CONTENT_TYPE.IMAGE,
          src: "", // TODO: add
        },
      ],
    },
  ],
};

const GET_STARTED = {
  name: "Get Started",
  sections: [
    WHAT_IS_VIMCAL_SUBSECTION,
    MOST_POPULAR_SUBSECTION,
    CONFERENCING_SUBSECTION,
  ],
};

const FEATURES = {
  name: "Features",
  sections: [
    isVimcalEAVersion() ? SLOTS_VIMCAL_EA_SUBSECTION : SLOTS_SUBSECTION,
    COMMAND_CENTER_SUBSECTION,
    TIME_TRAVEL_SUBSECTION,
    PERSONAL_LINKS_SUBSECTION,
    NLP_SUBSECTION,
    GROUP_VOTE_SUBSECTION,
    GROUP_VOTE_SPREADSHEET_SUBSECTION,
  ],
};

const ADVANCED_FEATURES = {
  name: "Advanced Features",
  sections: [
    isVimcalEAVersion() ? VIMCAL_EA_COLOR_CODING_AND_TAGGING_SUBSECTION : COLOR_CODING_AND_TAGGING_SUBSECTION,
    WEEKLY_METRICS_SUBSECTION,
    FREE_TIME_FINDER_SUBSECTION,
    TEMPLATES_SUBSECTION,
    GROUP_VOTE_SUBSECTION,
    FOCUS_MODE_SUBSECTION,
    INSTANT_OPEN_SUBSECTION,
  ],
};

const ACCOUNT_AND_BILLING = {
  name: "Account & Billing",
  sections: [
    ACCOUNT_SETUP_SUBSECTION,
    TEAMS_SUBSECTION,
    EDIT_YOUR_PROFILE_SUBSECTION,
    REFER_A_FRIEND_SUBSECTION,
  ],
};

const BEST_PRACTICES = {
  name: "Best Practices",
  sections: [
    TIME_ZONE_SUBSECTION,
    EXTERNAL_SCHEDULING_SUBSECTION,
    INTERNAL_SCHEDULING_SUBSECTION,
    KEYBOARD_SHORTCUTS_SUBSECTION,
  ],
};
const FAQS = {
  name: "FAQs",
  sections: [FAQ_SUBSECTION],
};

export const SECTIONS = [
  GET_STARTED,
  ACCOUNT_AND_BILLING,
  FEATURES,
  ADVANCED_FEATURES,
  BEST_PRACTICES,
  FAQS,
];

const PATH_TO_SUBSECTION_MAP = {
  [CONTENT_PATH.ABOUT]: WHAT_IS_VIMCAL_SUBSECTION,
  [CONTENT_PATH.ACCOUNT_SETUP]: ACCOUNT_SETUP_SUBSECTION,
  [CONTENT_PATH.CONFERENCING]: CONFERENCING_SUBSECTION,
  [CONTENT_PATH.TEAMS]: TEAMS_SUBSECTION,
  [CONTENT_PATH.EDIT_YOUR_PROFILE]: EDIT_YOUR_PROFILE_SUBSECTION,
  [CONTENT_PATH.MOST_POPULAR]: MOST_POPULAR_SUBSECTION,
  [CONTENT_PATH.SLOTS]: SLOTS_SUBSECTION,
  [CONTENT_PATH.GROUP_VOTE]: GROUP_VOTE_SUBSECTION,
  [CONTENT_PATH.TEMPLATES]: TEMPLATES_SUBSECTION,
  [CONTENT_PATH.FOCUS_MODE]: FOCUS_MODE_SUBSECTION,
  [CONTENT_PATH.TAGS_AND_METRICS]: TAGS_METRICS_SUBSECTION,
  [CONTENT_PATH.TIME_ZONES]: TIME_ZONE_SUBSECTION,
  [CONTENT_PATH.INTERNAL_SCHEDULING]: INTERNAL_SCHEDULING_SUBSECTION,
  [CONTENT_PATH.EXTERNAL_SCHEDULING]: EXTERNAL_SCHEDULING_SUBSECTION,
  [CONTENT_PATH.KEYBOARD_SHORTCUTS]: KEYBOARD_SHORTCUTS_SUBSECTION,
  [CONTENT_PATH.EVENTS]: EVENTS_SUBSECTION,
  [CONTENT_PATH.FAQ]: FAQ_SUBSECTION,
  [CONTENT_PATH.FREE_TIME_FINDER]: FREE_TIME_FINDER_SUBSECTION,
  [CONTENT_PATH.COLOR_CODING_AND_TAGGING]: COLOR_CODING_AND_TAGGING_SUBSECTION,
  [CONTENT_PATH.COLOR_CODING_AND_TAGGING_VIMCAL_EA]: VIMCAL_EA_COLOR_CODING_AND_TAGGING_SUBSECTION,
  [CONTENT_PATH.WEEKLY_METRICS]: WEEKLY_METRICS_SUBSECTION,
  [CONTENT_PATH.REFER_A_FRIEND]: REFER_A_FRIEND_SUBSECTION,
  [CONTENT_PATH.POSSIBLE_ISSUES_GOOGLE]: POSSIBLE_ISSUES_GOOGLE_SUBSECTION,
  [CONTENT_PATH.PERSONAL_LINKS]: PERSONAL_LINKS_SUBSECTION,
  [CONTENT_PATH.COMMAND_CENTER]: COMMAND_CENTER_SUBSECTION,
  [CONTENT_PATH.NLP]: NLP_SUBSECTION,
  [CONTENT_PATH.INSANT_OPEN]: INSTANT_OPEN_SUBSECTION,
  [CONTENT_PATH.TIME_TRAVEL]: TIME_TRAVEL_SUBSECTION,
  [CONTENT_PATH.GROUP_VOTE_SPREADSHEET]: GROUP_VOTE_SPREADSHEET_SUBSECTION,
  [CONTENT_PATH.SLOTS_VIMCAL_EA]: SLOTS_VIMCAL_EA_SUBSECTION,
};

export function getSubSectionContentFromPath(path) {
  return PATH_TO_SUBSECTION_MAP[path] ?? WHAT_IS_VIMCAL_SUBSECTION;
}
