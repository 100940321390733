export function getInputStringFromEvent(e) {
  return e?.target?.value ?? "";
}

export function getBoldTextWithinString(text) {
  if (!text?.includes("<strong>")) {
    return [text];
  }
  const parts = [];
  let remainingText = text;
  let strongStartIndex;
  let strongEndIndex;
  
  while ((strongStartIndex = remainingText.indexOf("<strong>")) !== -1) {
    // Add text before the <strong> tag
    if (strongStartIndex > 0) {
      parts.push({
        text: remainingText.substring(0, strongStartIndex),
        isBold: false
      });
    }
    
    // Find the closing tag
    strongEndIndex = remainingText.indexOf("</strong>", strongStartIndex);
    if (strongEndIndex === -1) {
      // No closing tag found, treat the rest as non-bold
      parts.push({
        text: remainingText,
        isBold: false
      });
      break;
    }
    
    // Extract the bold text (without the tags)
    const boldText = remainingText.substring(
      strongStartIndex + "<strong>".length, 
      strongEndIndex
    );
    
    parts.push({
      text: boldText,
      isBold: true
    });
    
    // Update remaining text to continue processing
    remainingText = remainingText.substring(strongEndIndex + "</strong>".length);
  }
  
  // Add any remaining text after the last </strong>
  if (remainingText) {
    parts.push({
      text: remainingText,
      isBold: false
    });
  }
  return parts;
}

// parts from getBoldTextWithinString;
export function renderBoldText(parts) {
  return parts.map((part, index) => 
    part.isBold ? <strong className="font-weight-600" key={index}>{part.text}</strong> : part.text
  );
}

export function doesStringContainBoldText(text) {
  return text?.includes("<strong>");
}
