import classNames from "classnames";
import React from "react";
import { doesStringContainBoldText, getBoldTextWithinString, renderBoldText } from "../../lib/stringFunctions";
import { openLinkOnSamePage } from "../../lib/appFunctions";

export default function ContentBodyText({ body }) {
  const text = body?.text ?? "";
  const isSubheader = body?.isSubheader;
  const link = body?.link;
  if (doesStringContainBoldText(text)) {
    // Split the text by <strong> and </strong> tags
    const parts = getBoldTextWithinString(text);
    return (
      <div
        className={classNames(
          "content-text-body default-body-section-margin-top",
          isSubheader ? "subheader-text" : "",
          link ? "content-type-link" : ""
        )}
        onClick={() => {
          if (link) {
            openLinkOnSamePage(link);
          }
        }}
      >
        {renderBoldText(parts)}
      </div>
    );
  }
  return (
    <div
      className={classNames(
        "content-text-body default-body-section-margin-top",
        isSubheader ? "subheader-text" : "",
        link ? "content-type-link" : ""
      )}
      onClick={() => {
        if (link) {
          openLinkOnSamePage(link);
        }
      }}
    >
      {text}
    </div>
  );
}
